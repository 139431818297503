import { Tooltip } from "antd";
import styles from "./UsersActions.module.css";
import { Can } from "../../casl";
import { EUserType, IUser } from "../types";
import { FC, useCallback } from "react";
import { IconEdit, IconMore, IconPlus, IconTrash } from "../../../assets";
import { Body2 } from "../../../components/Typography";
import { useTranslation } from "react-i18next";
import { LMIconButton } from "../../../components/LMIconButton";
import { useUser } from "../hooks";
import { LMNewButton } from "../../../components/LMNewButton";

interface Props {
  user: IUser;
  onSetUserToEdit: (user: IUser) => void;
  onSetUserToRemove: (user: IUser) => void;
  onAddRole: (user: IUser) => void;
}

export const UsersActions: FC<Props> = ({
  onSetUserToEdit,
  onSetUserToRemove,
  user,
  onAddRole,
}) => {
  const { t } = useTranslation("users");
  const { userType: currentUserType, currentUser } = useUser();

  const canEdit = useCallback(() => {
    if (currentUser?.id === user.userId) {
      return true;
    }
    if (
      currentUserType === EUserType.SuperAdmin &&
      user.userType !== EUserType.SuperAdmin
    ) {
      return true;
    }
    if (user.userType === EUserType.User) {
      return true;
    }
    return false;
  }, [currentUserType, user, currentUser]);

  if (!canEdit()) {
    return null;
  }

  const handleAssignModalOpen = () => {
    onAddRole(user);
  };

  return (
    <div className={styles.container}>
      <LMNewButton
        className={styles["add-button"]}
        color="transparent"
        onClick={handleAssignModalOpen}
      >
        <IconPlus stroke="var(--color-primary-80)" /> {t("addRole")}
      </LMNewButton>
      <Tooltip
        color="white"
        arrow={false}
        className={styles.tooltip}
        placement="bottomRight"
        title={
          <div className={styles.buttons}>
            <Can I="manage" a="user">
              <button
                className={styles.button}
                onClick={() => onSetUserToEdit(user)}
              >
                <IconEdit /> <Body2>{t("edit")}</Body2>
              </button>
            </Can>
            <Can I="manage" a="super">
              <button
                className={styles.button}
                onClick={() => onSetUserToRemove(user)}
              >
                <IconTrash /> <Body2>{t("delete")}</Body2>
              </button>
            </Can>
          </div>
        }
      >
        <LMIconButton className={styles["icon-button"]}>
          <IconMore />
        </LMIconButton>
      </Tooltip>
    </div>
  );
};
