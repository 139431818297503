import { useTranslation } from "react-i18next";
import { IUser, IUserRole } from "../types";
import { UserRolesListItem } from "./UserRolesListItem";
import { Label } from "../../../components/Typography";
import { useAppNotifications } from "../../../components/LMNotifications";
import styles from "./UserRolesList.module.css";

interface Props {
  user: IUser;
  onRefresh: () => void;
}

export const UserRolesList = ({ user, onRefresh }: Props) => {
  const { success, error, contextHolder } = useAppNotifications();
  const { t } = useTranslation("users");

  const onRevokeSuccess = () => {
    success(t("revokeSuccess"));
    onRefresh();
  };

  const onRevokeError = () => {
    error(t("revokeError"));
    onRefresh();
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <Label>{t("permissions")}</Label>
      <div className={styles.content}>
        {user.roles.map((role: IUserRole) => (
          <UserRolesListItem
            key={role.id}
            userRole={role}
            userId={user.userId}
            onSuccess={onRevokeSuccess}
            onError={onRevokeError}
          />
        ))}
      </div>
    </div>
  );
};
