import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles, fetchSimpleList } from "../slice";
import { IRolesListItem } from "../types";
import { AppDispatch, RootState } from "../../../app/store";
import { LoaderWrapper } from "../../../components";
import { UserRoleItem } from "../UserRoleItem";
import { useAppNotifications } from "../../../components/LMNotifications";
import { AssignUserModal } from "../AssignUserModal";
import { useTranslation } from "react-i18next";
import styles from "./UserRoles.module.css";

export const UserRoles = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("users");
  const { success, error, contextHolder } = useAppNotifications();
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

  const getRoles = useCallback(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const getUsers = useCallback(() => {
    dispatch(fetchSimpleList());
  }, [dispatch]);

  const roles = useSelector((state: RootState) => state.users.roles);
  const status = useSelector((state: RootState) => state.users.status);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onAssignSuccess = () => {
    success(t("assignSuccess"));
    getRoles();
  };

  const onAssignError = () => {
    error(t("assignError"));
  };

  const onRevokeSuccess = () => {
    success(t("revokeSuccess"));
    getRoles();
  };

  const onRevokeError = () => {
    error(t("revokeError"));
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <LoaderWrapper status={status}>
        <div className={styles.roles}>
          {roles.map((role: IRolesListItem) => (
            <UserRoleItem
              key={role.role.id}
              userRole={role}
              onSelectRoleId={setSelectedRoleId}
              onSuccess={onRevokeSuccess}
              onError={onRevokeError}
            />
          ))}
        </div>
      </LoaderWrapper>
      <AssignUserModal
        onCancel={() => setSelectedRoleId(null)}
        roleId={selectedRoleId}
        onRefresh={getRoles}
        onSuccess={onAssignSuccess}
        onError={onAssignError}
      />
    </div>
  );
};
