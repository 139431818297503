import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useLink } from "../../components/LMLink";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { TFunction } from "i18next";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Button1 } from "../../components/Typography";
import { getMaintenanceKey } from "./utils";
import { useLocation } from "react-router-dom";
import { fetchProductPortfolio } from "../../modules/productsPortfolio";
import { fetchMetadataList } from "../../modules/metadata";
import { AbilityContext } from "../../modules/casl";
import { AnyMongoAbility } from "@casl/ability";
import styles from "./HeaderTitle.module.css";

export const getKeys = (
  t: TFunction,
  portfolioListCount: number,
  metadataListCount: number,
  ability: AnyMongoAbility
) => {
  const keys = [];
  if (ability.can("get", "products")) {
    keys.push({
      key: "portfolio",
      label: (
        <div className={styles["with-total"]}>
          {t("productsPortfolio") as ReactNode}{" "}
          <Button1 className={styles["count"]}>{portfolioListCount}</Button1>
        </div>
      ),
    });
  }
  if (ability.can("metadata:get", "products")) {
    keys.push({
      key: "metadata",
      label: (
        <div className={styles["with-total"]}>
          {t("metadata") as ReactNode}{" "}
          <Button1 className={styles["count"]}>{metadataListCount}</Button1>
        </div>
      ),
    });
  }
  return keys;
};

export const ProductPortfolioTabs = () => {
  const navigate = useLink();
  const ability = useContext(AbilityContext);
  const location = useLocation();
  const { t } = useTranslation("navigation");
  const dispatch = useDispatch<AppDispatch>();
  const [activeKey, setActiveKey] = useState(
    getMaintenanceKey(location.pathname)
  );

  const portfolioListCount = useSelector((state: RootState) => {
    return state.productsPortfolio.filters.total;
  });

  const metadataListCount = useSelector((state: RootState) => {
    return state.metadata.total;
  });

  const handleTabsChange = (key: string) => {
    console.log(key);
    setActiveKey(key);
    navigate(`/products-portfolio/${key}`);
  };

  const getCounts = useCallback(() => {
    dispatch(
      fetchProductPortfolio({
        total: 0,
        page: 1,
        pageSize: 10,
        searchString: "",
        nameFilterSort: "none",
        categoriesFilter: [],
        statusFilter: "",
        disabledSystemsFilter: false,
      })
    );
    dispatch(fetchMetadataList());
  }, [dispatch]);

  useEffect(() => {
    getCounts();
  }, [getCounts]);

  return (
    <Tabs
      className={styles.tabs}
      defaultActiveKey={activeKey}
      items={getKeys(t, portfolioListCount, metadataListCount, ability)}
      onChange={handleTabsChange}
    />
  );
};
