import { useContext, FC, useCallback, SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import { AbilityContext } from "../../casl";
import { IKey } from "../types";
import { updateOne, fetchActiveKeysCount } from "../slice";
import { AppDispatch } from "../../../app/store";
import { LMNewSwitch } from "../../../components/LMNewSwitch";
import styles from "./StatusCell.module.css";

interface Props {
  maintenanceKey: IKey;
  keyId: number;
  systemId: number;
}

export const StatusCell: FC<Props> = ({ keyId, maintenanceKey, systemId }) => {
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch<AppDispatch>();

  const onChange = useCallback(
    async (active: boolean, event: SyntheticEvent): Promise<void> => {
      event.stopPropagation();
      await dispatch(updateOne({ keyId, key: { active } }));
      await dispatch(fetchActiveKeysCount({ systemId }));
    },
    [keyId, dispatch, systemId]
  );

  return (
    <div className={styles.container}>
      {maintenanceKey.active}
      <LMNewSwitch
        disabled={!ability.can("update", "keys")}
        onChange={onChange}
        checked={maintenanceKey.active}
      />
    </div>
  );
};
