import { AxiosPromise } from "axios";
import { axios } from "../axios";
import {
  EUserType,
  IUserFormValues,
  IUser,
  IUsersListFilters,
  IRolesListItem,
} from "./types";
import { getPagination, getSearchFilter, getSortFilter } from "../../app/utils";
import { IApiList } from "../../app/types";

const getTypeFilter = (types: EUserType[]) => {
  if (types.length === 0) {
    return "";
  }
  let query = "";
  types.forEach((status) => {
    query = `${query}&userType=${status}`;
  });
  return query;
};


export const getUsers = (
  filters: IUsersListFilters
): AxiosPromise<IApiList<IUser>> => {
  //return Promise.resolve({ data: mock.list } as unknown as AxiosPromise);
  const {
    typeFilter,
    searchFilter,
    nameFilterSort,
    page,
    pageSize
  } = filters;
  return axios.get(
    `/users${getPagination(page, pageSize, true)}${getTypeFilter(
      typeFilter
    )}${getSearchFilter(searchFilter, "nameOrEmail", 2)}${getSortFilter(
      nameFilterSort
    )}`
  );
};

export const getAllUsers = (): AxiosPromise<IApiList<IUser>> => {
  return axios.get(`/users?page=1&pageSize=100`);
}

export const createUser = (entry: IUserFormValues): AxiosPromise => {
  return axios.post(`/users`, entry);
}

export const updateUser = (userId: string, entry: IUserFormValues): AxiosPromise => {
  return axios.patch(`/users/${userId}`, entry);
}

export const getUser = (userId: string): AxiosPromise<IUser> => {
  return axios.get(`/users/${userId}`);
}

export const deleteUser = (userId: string): AxiosPromise => {
  return axios.delete(`/users/${userId}/remove`);
}

export const getUserRoles = (): AxiosPromise<IApiList<IRolesListItem>> => {
  return axios.get(`/users/roles`);
}

export const assignUserToRole = (userId: string, roleId: string): AxiosPromise => {
  return axios.post(`/users/${userId}/roles`, [roleId]);
}

export const removeUserFromRole = (userId: string, roleId: string): AxiosPromise => {
  return axios.delete(`/users/${userId}/roles`, { data: [roleId] });
}
