import { useTranslation } from "react-i18next";
import { H2 } from "../../components/Typography";
import styles from "./HomeNoSystem.module.css";

export const HomeNoSystem = () => {
  const { t } = useTranslation("home");
  return (
    <div className={styles.container}>
      <H2>{t("selectSystem")}</H2>
    </div>
  );
};
