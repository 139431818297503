import { useTranslation } from "react-i18next";
import { LMNewButton } from "../../../components/LMNewButton";
import { userNamesTranslations } from "../utils";
import { Body2, Button2 } from "../../../components/Typography";
import { IconTimes } from "../../../assets";
import { removeUserFromRole } from "../api";
import { IUserRole } from "../types";
import styles from "./UserRolesList.module.css";

interface Props {
  userRole: IUserRole;
  onSuccess?: () => void;
  onError?: () => void;
  userId?: string;
}

export const UserRolesListItem = ({
  userRole,
  onSuccess,
  onError,
  userId,
}: Props) => {
  const { t } = useTranslation("userRoles");
  const { t: tUsers } = useTranslation("users");

  const roleKey =
    userNamesTranslations[userRole.name as keyof typeof userNamesTranslations];

  if (!roleKey) return null;

  const roleText = t(`${roleKey}`, { returnObjects: true }) as {
    title: string;
    description: string;
  };

  const handleRevokeUser = async () => {
    if (!userId || !onSuccess || !onError) return;
    try {
      await removeUserFromRole(userId, userRole.id);
      onSuccess();
    } catch {
      onError();
    }
  };

  return (
    <div className={styles["role-container"]}>
      <div className={styles["role-content"]}>
        <Button2 className={styles.role}>{roleText.title}</Button2>
        <Body2 className={styles.description}>{roleText.description}</Body2>
      </div>
      {userId && (
        <div className={styles["button-container"]}>
          <LMNewButton color="transparent" onClick={handleRevokeUser}>
            {tUsers("revoke")} <IconTimes stroke="var(--color-black)" />
          </LMNewButton>
        </div>
      )}
    </div>
  );
};
