import { FC, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  IOrder,
  EOrderStatus,
  IOrderShipping,
  IOrderPart,
  IOrdersListFilters,
} from "../types";
import { ColumnType } from "antd/lib/table";
import { actions } from "../slice";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses, SortTypes } from "../../../app/types";
import { DATE_FORMAT, TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { LMTable } from "../../../components/LMTable";
import { OrderStatus } from "../OrderStatus";
import { useLink } from "../../../components/LMLink";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconExport, IconProcessOrder } from "../../../assets";
import { OrderPartsList } from "../OrderPartsList";
import { extendByDetails } from "../utils";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { processOrders } from "../api";
import { useAppNotifications } from "../../../components/LMNotifications";
import { AbilityContext, Can } from "../../casl";
import styles from "./OrdersList.module.css";

interface Props {
  orders: IOrder[];
  selectedOrdersToProcess: IOrder[];
  filters: IOrdersListFilters;
  status: ApiStatuses;
  onExport: () => void;
  onRefresh: () => void;
}

export const OrdersList: FC<Props> = ({
  orders,
  selectedOrdersToProcess,
  filters,
  onExport,
  status,
  onRefresh,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useLink();
  const {
    contextHolder,
    error: errorNotification,
    success,
    warning,
  } = useAppNotifications();
  const { t } = useTranslation("orders");
  const ability = useContext(AbilityContext);

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  const handlePageSizeChange = (updatedSize: string) => {
    dispatch(actions.setPageSize(Number(updatedSize)));
    dispatch(actions.setPage(1));
  };

  const handleSortDateChange = (column: string, order: SortTypes) => {
    dispatch(actions.setDateSort(order));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IOrder[]) => {
      dispatch(actions.setSelectedOrdersToProcess(selectedRows));
    },
    getCheckboxProps: (record: IOrder) => ({
      disabled: record.status !== EOrderStatus.Pending,
      name: record.id,
      onChange: (e: CheckboxChangeEvent) => {
        e.preventDefault();
      },
    }),
  };

  const columns = useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        key: "id",
        fixed: "left",
        width: 120,
      },
      {
        title: t("time"),
        key: "created",
        dataIndex: "createdOn",
        width: 190,
        render: (createdOn: string) => {
          return (
            <>
              {format(new Date(createdOn), DATE_FORMAT)}
              <span className={styles.time}>
                &nbsp;
                {format(new Date(createdOn), TIME_NO_SECONDS_FORMAT)}
              </span>
            </>
          );
        },
        sorter: true,
      },
      {
        title: t("source"),
        key: "source",
        dataIndex: "internal",
        width: 80,
        render: (internal: boolean) => (internal ? t("user") : t("api")),
      },
      {
        title: t("status"),
        dataIndex: "status",
        key: "status",
        width: 150,
        render: (status: EOrderStatus) => <OrderStatus status={status} />,
      },
      {
        title: t("delivery"),
        dataIndex: "shippings",
        key: "shippings",
        width: 140,
        render: (shippings?: IOrderShipping[]) => (
          <OrderStatus
            status={
              shippings && shippings[shippings.length - 1]
                ? shippings[shippings.length - 1].status
                : undefined
            }
          />
        ),
      },
      {
        title: t("packs"),
        key: "packs",
        dataIndex: "packsCount",
        width: 100,
      },
      {
        title: t("parts"),
        key: "parts",
        dataIndex: "parts",
        width: 260,
        render: (parts: IOrderPart[]) =>
          parts ? (
            <OrderPartsList parts={parts as unknown as IOrderPart[]} />
          ) : (
            ""
          ),
      },
      ...extendByDetails(orders).detailsList.map((detailsItemName) => ({
        title: detailsItemName.title,
        key: detailsItemName.value,
        dataIndex: detailsItemName.value,
      })),
    ],
    [t, orders]
  ) as ColumnType<IOrder>[];
  const handleRowClick = (order: IOrder) => navigate(`/orders/${order.id}`);

  const onRow = (media: IOrder) => ({
    onClick: () => handleRowClick(media),
    style: { cursor: "pointer" },
  });

  const getTotalTitle = () => {
    if (selectedOrdersToProcess.length === 1) {
      return t("selectedOrder");
    }
    if (selectedOrdersToProcess.length > 0) {
      return t("selectedOrders", { amount: selectedOrdersToProcess.length });
    }
    return t("total");
  };

  const handleProcessOrders = async () => {
    if (!selectedOrdersToProcess.length) return;
    try {
      const response = await processOrders(
        selectedOrdersToProcess.map((order) => order.id)
      );
      onRefresh();
      if (response.status === 207) {
        warning(t("processPartiallySuccess"));
        return;
      }
      success(t("processSuccess"));
    } catch (error) {
      errorNotification(t("processError"));
    }
  };

  return (
    <>
      {contextHolder}
      <LMTable
        className={styles.table}
        loading={status === ApiStatuses.loading}
        rowSelection={
          ability.can("update", "orders") ? rowSelection : undefined
        }
        columns={columns}
        dataSource={extendByDetails(orders).ordersWithDetailsExpanded}
        total={{
          title: getTotalTitle(),
          amount: selectedOrdersToProcess.length ? undefined : filters.total,
        }}
        action={
          <div className={styles.buttons}>
            <Can I="update" a="orders">
              <LMNewButton
                color="default"
                onClick={handleProcessOrders}
                small
                iconRight={<IconProcessOrder />}
                disabled={!selectedOrdersToProcess.length}
              >
                {t("processMany")}
              </LMNewButton>
            </Can>
            <LMNewButton
              onClick={onExport}
              iconRight={<IconExport />}
              type="primary"
              className={styles.button}
              small
            >
              {t("export")}
            </LMNewButton>
          </div>
        }
        onChangePageSize={handlePageSizeChange}
        onChangePage={handlePageChange}
        onSortChange={handleSortDateChange}
        pageSize={filters.pageSize}
        page={filters.page}
        onRow={onRow}
        scroll={{ x: 1300 }}
      />
    </>
  );
};
