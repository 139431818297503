import { FC, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { Body1, H3 } from "../../../components/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { fetchKeyDetails } from "../slice";
import { LoaderWrapper } from "../../../components";
import { KeyDetails } from "./KeyDetails";
import { KeyUsageHistory } from "./KeyUsageHistory";
import styles from "./KeyDetailsDrawer.module.css";

interface Props {
  systemId: number;
}

export const KeyDetailsDrawer: FC<Props> = ({ systemId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("keys");

  const status = useSelector((state: RootState) => {
    return state.keys.keyDetailsStatus;
  });

  const keyDetails = useSelector((state: RootState) => {
    return state.keys.keyDetails;
  });

  const getKey = useCallback(() => {
    const keyId = searchParams.get("keyId");
    if (!keyId) return;
    dispatch(fetchKeyDetails(keyId));
  }, [searchParams, dispatch]);

  useEffect(() => {
    getKey();
  }, [getKey]);

  const handleClose = () => {
    searchParams.delete("keyId");
    setSearchParams(searchParams);
  };

  return (
    <Drawer
      title={
        keyDetails ? (
          <div className={styles.header}>
            <H3>
              {t("detailsId")}: {keyDetails.id}
            </H3>
            <Body1 className={styles.name}>{keyDetails.name}</Body1>
          </div>
        ) : (
          <></>
        )
      }
      placement="right"
      onClose={handleClose}
      open={!!searchParams.get("keyId")}
      className={styles.drawer}
    >
      <LoaderWrapper status={status}>
        {keyDetails ? (
          <div className={styles.content}>
            <KeyDetails details={keyDetails} systemId={systemId} />
            <KeyUsageHistory usages={keyDetails.usages} />
          </div>
        ) : (
          <></>
        )}
      </LoaderWrapper>
    </Drawer>
  );
};
