import { FC, MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { IUser, IUsersListFilters } from "../types";
import { actions } from "../slice";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses, SortTypes } from "../../../app/types";
import { LMTable } from "../../../components/LMTable";
import { LMNewButton } from "../../../components/LMNewButton";
import {
  IconCollapsed,
  IconExpanded,
  IconPlus,
  IconUserDelete,
} from "../../../assets";
import { getColumns } from "../utils";
import { UserFormModal } from "../UserFormModal";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { Body1, H3 } from "../../../components/Typography";
import { deleteUser } from "../api";
import { useAppNotifications } from "../../../components/LMNotifications";
import styles from "./UsersList.module.css";
import { UserRolesList } from "../UserRolesList";
import { AssignRoleModal } from "../AssignRoleModal";

interface Props {
  users: IUser[];
  filters: IUsersListFilters;
  status: ApiStatuses;
  onRefresh: () => void;
}

export const UsersList: FC<Props> = ({ users, filters, status, onRefresh }) => {
  const { contextHolder, success, error } = useAppNotifications();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("users");
  const [userToDelete, setUserToDelete] = useState<IUser>();
  const [userToEdit, setUserToEdit] = useState<IUser>();
  const [userToAssign, setUserToAssign] = useState<IUser | null>(null);

  const handleSortNameChange = (column: string, order: SortTypes) => {
    dispatch(actions.setDateSort(order));
  };

  const handleFormModalsClose = () => {
    searchParams.delete("create");
    setSearchParams(searchParams);
    if (!userToEdit) return;
    setUserToEdit(undefined);
  };

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  const handlePageSizeChange = (updatedSize: string) => {
    dispatch(actions.setPageSize(Number(updatedSize)));
    dispatch(actions.setPage(1));
  };

  const handleDeleteUser = async () => {
    if (!userToDelete) return;
    try {
      await deleteUser(userToDelete.userId);
      onRefresh();
      setUserToDelete(undefined);
      success(t("deleteUserSuccess"));
    } catch {
      error(t("deleteUserError"));
    }
  };

  const handleAssignRoleSuccess = () => {
    success(t("assignRoleSuccess"));
  };

  const handleAssignRoleError = () => {
    success(t("assignRoleError"));
  };

  const handleCreateUser = () => {
    searchParams.set("create", "true");
    setSearchParams(searchParams);
  };

  const columns = getColumns(
    t,
    setUserToEdit,
    setUserToDelete,
    setUserToAssign
  );

  return (
    <>
      {contextHolder}
      <LMTable
        loading={status === ApiStatuses.loading}
        columns={columns}
        dataSource={users}
        className={styles["table-container"]}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <IconExpanded
                className={styles.icon}
                onClick={(e: unknown) =>
                  onExpand(record, e as MouseEvent<HTMLElement>)
                }
              />
            ) : (
              <IconCollapsed
                className={styles.icon}
                onClick={(e: unknown) =>
                  onExpand(record, e as MouseEvent<HTMLElement>)
                }
              />
            ),
          expandedRowRender: (user: IUser) => {
            return <UserRolesList user={user} onRefresh={onRefresh} />;
          },
        }}
        total={{ title: t("total"), amount: filters.total }}
        action={
          <LMNewButton
            onClick={handleCreateUser}
            iconRight={<IconPlus />}
            type="primary"
            small
          >
            {t("add")}
          </LMNewButton>
        }
        onSortChange={handleSortNameChange}
        onChangePageSize={handlePageSizeChange}
        onChangePage={handlePageChange}
        rowKey="userId"
        scroll={{ x: 1300 }}
        pageSize={filters.pageSize}
        page={filters.page}
      />
      <UserFormModal
        onRefresh={onRefresh}
        user={userToEdit}
        onClose={handleFormModalsClose}
      />
      <AssignRoleModal
        user={userToAssign}
        onCancel={() => setUserToAssign(null)}
        onRefresh={onRefresh}
        onSuccess={handleAssignRoleSuccess}
        onError={handleAssignRoleError}
      />
      <ConfirmationModal
        content={
          <div className={styles["delete-user-content"]}>
            <IconUserDelete />
            <H3>{t("deleteUserConfirmation")}</H3>
            <Body1 className={styles["delete-user-description"]}>
              {t("deleteUserConfirmationDescription")}
            </Body1>
          </div>
        }
        confirmText={t("delete")}
        open={!!userToDelete}
        onConfirm={handleDeleteUser}
        onCancel={() => setUserToDelete(undefined)}
        confirmButtonColor="danger-fluid"
      />
    </>
  );
};
