import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import { LMButton, PageHeader, PageWrapper } from "../../components";
import {
  CreateIndexingRequestDrawer,
  indexingActions,
  IndexingDetails,
  IndexingList,
} from "../../modules/indexing";
import styles from "./Indexing.module.css";
import { Can } from "../../modules/casl";

interface Props {
  systemId: number;
}

export const Indexing: FC<Props> = ({ systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation("indexing");

  const { selectedIndexingRequestId } = useSelector((state: RootState) => {
    return state.indexing;
  });

  const onClickCreateNewIndexingRequest = useCallback(() => {
    setSearchParams({ create: "true" });
    dispatch(indexingActions.setIsCreateDrawerOpen(true));
  }, [dispatch, setSearchParams]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const requestId = search.get("requestId");
    if (requestId) {
      dispatch(indexingActions.setIndexingRequest(parseInt(requestId, 0)));
    }
    return () => {
      dispatch(indexingActions.setIndexingRequest());
    };
  }, [location, dispatch]);

  return (
    <PageWrapper>
      <PageHeader
        title={t("listTitle")}
        actionComponent={
          <div className={styles["action-container"]}>
            <Can I="create" a="maintenance">
              <LMButton
                size={"large"}
                type={"primary"}
                block
                onClick={onClickCreateNewIndexingRequest}
              >
                {t("create")}
              </LMButton>
            </Can>
          </div>
        }
      />
      <div className={styles.container}>
        <IndexingList systemId={systemId} />
        {selectedIndexingRequestId && (
          <div className={styles.details}>
            <IndexingDetails
              requestId={selectedIndexingRequestId}
              systemId={systemId}
              key={selectedIndexingRequestId}
            />
          </div>
        )}
      </div>
      <CreateIndexingRequestDrawer systemId={systemId} />
    </PageWrapper>
  );
};
