import { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Button2 } from "../../../components/Typography";
import { DATE_FORMAT, TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { LMNewSwitch } from "../../../components/LMNewSwitch";
import { IKeyDetails } from "../types";
import styles from "./KeyDetailsDrawer.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { fetchActiveKeysCount, updateOne } from "../slice";
import { AbilityContext } from "../../casl";

interface Props {
  details: IKeyDetails;
  systemId: number;
}

export const KeyDetails: FC<Props> = ({ details, systemId }) => {
  const { t } = useTranslation("keys");
  const dispatch = useDispatch<AppDispatch>();
  const [active, setActive] = useState(details.active);
  const ability = useContext(AbilityContext);

  const onActiveChange = useCallback(
    async (active: boolean): Promise<void> => {
      await dispatch(
        updateOne({ keyId: details.id, key: { active } })
      ).unwrap();
      await dispatch(fetchActiveKeysCount({ systemId })).unwrap();
      setActive(active);
    },
    [details, dispatch, systemId]
  );

  return (
    <div className={styles.fields}>
      <div className={styles.field}>
        <Button2 className={styles["field-name"]}>
          {t("detailsCreatedBy")}
        </Button2>
        <Button2 className={styles["field-value"]}>{details.createdBy}</Button2>
      </div>
      {details.createdOn && (
        <div className={styles.field}>
          <Button2 className={styles["field-name"]}>
            {t("detailsDateCreated")}
          </Button2>
          <Button2 className={styles["field-value"]}>
            {format(new Date(), DATE_FORMAT)}{" "}
            <span className={styles.light}>
              {format(new Date(details.createdOn), TIME_NO_SECONDS_FORMAT)}
            </span>
          </Button2>
        </div>
      )}
      <div className={styles.field}>
        <Button2 className={styles["field-name"]}>{t("detailsStatus")}</Button2>
        <Button2 className={styles["field-value"]}>
          <LMNewSwitch
            disabled={!ability.can("update", "keys")}
            checked={active}
            onChange={onActiveChange}
          />
          <span className={styles.status}>
            {details.active ? t("active") : t("inactive")}
          </span>
        </Button2>
      </div>
      {details.phoneNumber && (
        <div className={styles.field}>
          <Button2 className={styles["field-name"]}>
            {t("detailsPhone")}
          </Button2>
          <Button2 className={styles["field-value"]}>
            {details.phoneNumber}
          </Button2>
        </div>
      )}
      <div className={styles.field}>
        <Button2 className={styles["field-name"]}>{t("detailsType")}</Button2>
        <Button2 className={styles["field-value"]}>{t(details.type)}</Button2>
      </div>
      <div className={styles.field}>
        <Button2 className={styles["field-name"]}>
          {t("detailsPrivileges")}
        </Button2>
        <Button2 className={styles["field-value"]}>
          {details.privileges.map((p) => t(p)).join(", ")}
        </Button2>
      </div>
    </div>
  );
};
