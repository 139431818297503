import { AbilityContext } from "../casl";
import { useCallback, useContext, useEffect, useState } from "react";
import { EUserType, ISimpleUser } from "./types";
import { useAuth0 } from "@auth0/auth0-react";

export const useUser = () => {
  const ability = useContext(AbilityContext);
  const { getIdTokenClaims } = useAuth0();
  const [currentUser, setCurrentUser] = useState<ISimpleUser | null>(null);

  const getCurrentUser = useCallback(async () => {
    const claims = await getIdTokenClaims();
    setCurrentUser({
      userName: claims?.name as string,
      id: claims?.sub as string,
    });
  }, [getIdTokenClaims]);

  const getUserType = useCallback(() => {
    if (ability.can("manage", "super")) {
      return EUserType.SuperAdmin;
    }
    if (ability.can("manage", "user")) {
      return EUserType.Admin;
    }
    return EUserType.User;
  }, [ability]);

  const getAvailableUserTypesList = useCallback(() => {
    const list = [EUserType.User];
    const userType = getUserType();
    if (userType === EUserType.SuperAdmin) {
      list.push(EUserType.Admin);
    }
    return list;
  }, [getUserType]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return {
    userType: getUserType(),
    availableUserTypesList: getAvailableUserTypesList(),
    currentUser,
  };
};
