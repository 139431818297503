import { Routes as Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "../../modules/auth";
import { Orders } from "../../pages/Orders";
import { System } from "../../pages/System";
import { Replenishment } from "../../pages/Replenishment";
import { Transfers } from "../../pages/Transfers";
import { Monitoring } from "../../pages/Monitoring";
import { AbilityContext } from "../../modules/casl";
import { FC, useCallback, useContext } from "react";
import { RouteNotMatch } from "../../modules/navigation";
import { Indexing } from "../../pages/Indexing";
import { Home } from "../../pages/Home";
import { KeyList } from "../../pages/KeyList";
import { Order } from "../../pages/Order";
import { ProductsPortfolio } from "../../pages/ProductsPortfolio";
import { Store } from "../../pages/Store";
import { Control } from "../../pages/Control";
import { Support } from "../../pages/Support";
import { Metadata } from "../../pages/Metadata";
import { Warranty } from "../../pages/Warranty";
import { Users } from "../../pages/Users";
import { Profile } from "../../pages/Profile";
import { HomeNoSystem } from "../../pages/HomeNoSystem";

const isProduction = process.env.REACT_APP_ENV === "production";

export const Routes: FC = () => {
  const ability = useContext(AbilityContext);
  const params = isProduction ? "/:client/:systemId" : "/:systemId";

  const getRouteByEnabledSystems = useCallback(
    (route: string) => {
      if (ability.can("get", "systems")) {
        return `${params}${route}`;
      }
      return route;
    },
    [ability, params]
  );

  return (
    <Switch>
      <Route path="*" element={<RouteNotMatch />} />
      <Route path="/warranty" element={<Warranty />} />
      <Route
        path="/no-system"
        element={<ProtectedRoute component={HomeNoSystem} />}
      />
      <Route path={params} element={<ProtectedRoute component={Home} />} />
      <Route
        path={getRouteByEnabledSystems("/profile")}
        element={<ProtectedRoute component={Profile} />}
      />
      {ability.can("get", "orders") ? (
        <>
          <Route
            path={`${params}/orders`}
            element={<ProtectedRoute component={Orders} />}
          />
          <Route
            path={`${params}/orders/:orderId`}
            element={<ProtectedRoute component={Order} />}
          />
        </>
      ) : (
        <></>
      )}
      {ability.can("get", "maintenance") ? (
        <Route
          path={`${params}/maintenance/replenishment`}
          element={<ProtectedRoute component={Replenishment} />}
        />
      ) : (
        <></>
      )}
      {ability.can("get", "stock") ? (
        <Route
          path={`${params}/maintenance/transfers`}
          element={<ProtectedRoute component={Transfers} />}
        />
      ) : (
        <></>
      )}
      <Route
        path={`${params}/maintenance/indexing`}
        element={<ProtectedRoute component={Indexing} />}
      />
      {ability.can("get", "keys") ? (
        <Route
          path={`${params}/maintenance/keys`}
          element={<ProtectedRoute component={KeyList} />}
        />
      ) : (
        <></>
      )}

      <Route
        path={`${params}/system`}
        element={<ProtectedRoute component={System} />}
      />
      {ability.can("get", "products") ? (
        <Route
          path={getRouteByEnabledSystems("/products-portfolio/portfolio")}
          element={<ProtectedRoute component={ProductsPortfolio} />}
        />
      ) : (
        <></>
      )}
      {ability.can("metadata:get", "products") ? (
        <Route
          path={getRouteByEnabledSystems("/products-portfolio/metadata")}
          element={<ProtectedRoute component={Metadata} />}
        />
      ) : (
        <></>
      )}
      {ability.can("get", "control") && ability.can("create", "control") ? (
        <Route
          path={`${params}/control`}
          element={<ProtectedRoute component={Control} />}
        />
      ) : (
        <></>
      )}
      <Route
        path={`${params}/support`}
        element={<ProtectedRoute component={Support} />}
      />
      {ability.can("create", "orders") ? (
        <Route
          path={`${params}/store`}
          element={<ProtectedRoute component={Store} />}
        />
      ) : (
        <></>
      )}
      {ability.can("get", "media") ? (
        <Route
          path={`${params}/monitoring`}
          element={<ProtectedRoute component={Monitoring} />}
        />
      ) : (
        <></>
      )}
      {ability.can("manage", "super") || ability.can("manage", "user") ? (
        <Route
          path={getRouteByEnabledSystems("/users")}
          element={<ProtectedRoute component={Users} />}
        />
      ) : (
        <></>
      )}
    </Switch>
  );
};
