import { ApiStatuses, IPagination, Nullable, SortTypes } from "../../app/types";

export interface IUser {
  userId: string;
  email: string;
  name: string;
  roles: IUserRole[];
  userType: EUserType;
  authType: EUserAuthType;
}

export interface ISimpleUser {
  id: string;
  userName: string;
}

export enum EUserType {
  User = "User",
  Admin = "Admin",
  SuperAdmin = "SuperAdmin",
}

export enum EUserAuthType {
  auth0 = "Auth0",
  google = "Google",
}

export enum EUsersViewType {
  users = "users",
  permissions = "permissions",
}

export interface IUsersListFilters extends IPagination {
  searchFilter: string;
  typeFilter: EUserType[];
  nameFilterSort: SortTypes;
  viewType: Nullable<EUsersViewType>;
}

export interface IUsersSliceState {
  list: IUser[];
  profile?: IUser;
  simpleList: ISimpleUser[];
  filters: IUsersListFilters;
  status: ApiStatuses;
  profileStatus: ApiStatuses;
  rolesStatus: ApiStatuses;
  roles: IRolesListItem[];
}

export interface IUserFormValues {
  name: string;
  email: string;
  userType: EUserType;
}

export interface IRolesListItem {
  role: IUserRole;
  users: ISimpleUser[];
}

export interface IUserRole {
  id: string;
  name: string;
}
