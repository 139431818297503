import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { H3 } from "../../components/Typography";
import { AppDispatch, RootState } from "../../app/store";
import {
  fetchProfile,
  UserRolesListItem,
  IUserRole,
} from "../../modules/users";
import { LMNewInput, LoaderWrapper } from "../../components";
import styles from "./Profile.module.css";

export const Profile = () => {
  const { t } = useTranslation("profile");
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();

  const { profileStatus, profile } = useSelector((state: RootState) => {
    return state.users;
  });

  const getUser = useCallback(async () => {
    const claims = await getIdTokenClaims();
    const userId = (claims as unknown as { sub: string }).sub;
    dispatch(fetchProfile(userId));
  }, [getIdTokenClaims, dispatch]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <div className={styles.container}>
      <LoaderWrapper status={profileStatus}>
        {profile ? (
          <>
            <div className={styles.card}>
              <H3>{t("profile")}</H3>
              <Form
                layout={"vertical"}
                className={styles.form}
                autoComplete="off"
                initialValues={{
                  name: profile.name,
                  email: profile.email,
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item name="name" label={t("name")}>
                      <LMNewInput disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item name="email" label={t("email")}>
                      <LMNewInput disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className={styles.card}>
              <H3>{t("permissions")}</H3>
              <div className={styles.roles}>
                {profile.roles.map((role: IUserRole) => (
                  <UserRolesListItem key={role.id} userRole={role} />
                ))}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </LoaderWrapper>
    </div>
  );
};
