import { Navigate } from "react-router-dom";
import { getClientNameFromScope, getEnvironment } from "../auth";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../casl";

const isProduction = process.env.REACT_APP_ENV === "production";

export const RouteNotMatch = () => {
  const client = getEnvironment() as string;
  const ability = useAbility(AbilityContext);
  if (!ability.can("get", "systems")) {
    return <Navigate to="/no-system" />;
  }
  if (isProduction) {
    return <Navigate to={`/${getClientNameFromScope(client)}/1/`} />;
  }
  return <Navigate to={`/1/`} />;
};
