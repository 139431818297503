import { INavigationItem, INavigationItemSimple } from "./types";
import {
  IconControlNav,
  IconHomeNav,
  IconMaintenanceNav,
  IconMonitoringNav,
  IconOrdersNav,
  IconProductsPortfolioNav,
  IconStoreNav,
  IconSystemNav,
  IconUser,
  IconUsers,
} from "../../assets";
import { AnyMongoAbility } from "@casl/ability";

const getAccessRoute = (routes: Record<string, boolean>) => {
  let accessRoute = "";
  Object.keys(routes).forEach((route) => {
    if (routes[route] && !accessRoute) {
      accessRoute = route;
    }
  });
  return accessRoute;
};

export const getOrganizationItems = (ability: AnyMongoAbility) => {
  const items = [];
  items.push({
    route: "/profile",
    translationKey: "profile",
    nestedItems: [],
    icon: <IconUser />,
    access: "all",
    tenantLevel: true,
  });
  if (
    ability.can("metadata:get", "products") ||
    ability.can("get", "products")
  ) {
    items.push({
      route: getAccessRoute({
        "/products-portfolio/portfolio": ability.can("get", "products"),
        "/products-portfolio/metadata": ability.can("metadata:get", "products"),
      }),
      translationKey: "productsPortfolio",
      nestedItems: [],
      icon: <IconProductsPortfolioNav />,
      access: "products:get|products:metadata:get",
      tenantLevel: true,
    });
  }
  if (ability.can("manage", "super") || ability.can("manage", "user")) {
    items.push({
      route: "/users",
      translationKey: "users",
      nestedItems: [],
      icon: <IconUsers />,
      access: "super:manage|user:manage",
      tenantLevel: true,
    });
  }

  return items;
};

export const getRegularItems = (ability: AnyMongoAbility) => {
  if (!ability.can("get", "systems")) {
    return [];
  }
  const items = [
    {
      route: "/",
      translationKey: "home",
      nestedItems: [],
      icon: <IconHomeNav />,
    },
  ];
  if (ability.can("get", "orders")) {
    items.push({
      route: "/orders",
      translationKey: "orders",
      nestedItems: [],
      icon: <IconOrdersNav />,
    });
  }
  items.push({
    route: "/system",
    translationKey: "system",
    nestedItems: [],
    icon: <IconSystemNav />,
  });
  if (
    ability.can("get", "maintenance") ||
    ability.can("get", "keys") ||
    ability.can("get", "stock")
  ) {
    items.push({
      route: getAccessRoute({
        "/maintenance/keys": ability.can("get", "keys"),
        "/maintenance/replenishment": ability.can("get", "maintenance"),
        "/maintenance/indexing": true,
        "/maintenance/transfers": ability.can("get", "stock"),
      }),
      translationKey: "maintenance",
      nestedItems: [],
      icon: <IconMaintenanceNav />,
    });
  }
  if (ability.can("get", "media")) {
    items.push({
      route: "/monitoring",
      translationKey: "monitoring",
      nestedItems: [],
      icon: <IconMonitoringNav />,
    });
  }
  if (ability.can("get", "control") && ability.can("create", "control")) {
    items.push({
      route: "/control",
      translationKey: "control",
      nestedItems: [],
      icon: <IconControlNav />,
    });
  }
  if (ability.can("create", "orders")) {
    items.push({
      route: "/store",
      translationKey: "store",
      nestedItems: [],
      icon: <IconStoreNav />,
    });
  }
  return items;
};

export const getItems = (isOrganization: boolean, ability: AnyMongoAbility) => {
  if (isOrganization) {
    return getOrganizationItems(ability);
  }
  return getRegularItems(ability);
};

export const findItemKeyByRoute = (route: string, ability: AnyMongoAbility) => {
  let item: INavigationItemSimple | undefined;
  [...getRegularItems(ability), ...getOrganizationItems(ability)].forEach(
    (navigationItem) => {
      if (navigationItem.route === route) {
        item = navigationItem;
        return;
      }
      if (navigationItem.nestedItems) {
        navigationItem.nestedItems.forEach((nestedItem: INavigationItem) => {
          if (nestedItem.route === route) {
            item = nestedItem;
            return;
          }
        });
      }
    }
  );
  return item;
};

export const findSubKeyByRoute = (route: string, ability: AnyMongoAbility) => {
  let item: INavigationItem | undefined;
  [...getRegularItems(ability), ...getOrganizationItems(ability)].forEach(
    (navigationItem) => {
      if (
        navigationItem.route === route ||
        navigationItem.nestedItems.find(
          (i: INavigationItem) => i.route === route
        )
      ) {
        item = navigationItem;
        return;
      }
    }
  );
  return item;
};
