import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EUsersViewType,
  EUserType,
  IUsersListFilters,
  IUsersSliceState,
} from "./types";
import { ApiStatuses, SortTypes } from "../../app/types";
import { getAllUsers, getUser, getUserRoles, getUsers } from "./api";

export const initialState: IUsersSliceState = {
  list: [],
  simpleList: [],
  filters: {
    searchFilter: "",
    typeFilter: [],
    nameFilterSort: "none",
    viewType: null,
    page: 1,
    pageSize: 20,
    total: 0
  },
  status: ApiStatuses.initial,
  profileStatus: ApiStatuses.initial,
  rolesStatus: ApiStatuses.initial,
  roles: [],
};

export const fetchList = createAsyncThunk(
  "users/fetchList",
  async (filters: IUsersListFilters) => {
    const response = await getUsers(
      filters
    );
    return response.data;
  }
);

export const fetchProfile = createAsyncThunk(
  "users/fetchProfile",
  async (userId: string) => {
    const response = await getUser(userId);
    return response.data;
  }
);

export const fetchSimpleList = createAsyncThunk(
  "users/fetchSimpleList",
  async () => {
    const response = await getAllUsers();
    return response.data;
  }
);

export const fetchRoles = createAsyncThunk(
  "users/fetchRoles",
  async () => {
    const response = await getUserRoles();
    return response.data;
  }
);

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setFilters(
      state: IUsersSliceState,
      action: PayloadAction<{
        search: string;
        types: string;
        viewType: string;
      }>
    ) {
      const { search, types, viewType } = action.payload;
      state.filters.searchFilter = search;
      state.filters.typeFilter = types
        ? (types.split(",") as EUserType[])
        : [];
      
      state.filters.viewType = viewType as EUsersViewType;
      state.filters.page = 1;
    },
    setDateSort(state: IUsersSliceState, action: PayloadAction<SortTypes>) {
      state.filters.nameFilterSort = action.payload;
    },
    clearFilters(state: IUsersSliceState) {
      state.filters.typeFilter = [];
      state.filters.searchFilter = "";
    },
    setPage(state: IUsersSliceState, action: PayloadAction<number>) {
      state.filters.page = action.payload;
    },
    setPageSize(state: IUsersSliceState, action: PayloadAction<number>) {
      state.filters.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.list = action.payload.values;
        state.filters.total = action.payload.count;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchProfile
      .addCase(fetchProfile.pending, (state) => {
        state.profileStatus = ApiStatuses.loading;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileStatus = ApiStatuses.success;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.profileStatus = ApiStatuses.fail;
      })
      // fetchSimpleList
      .addCase(fetchSimpleList.fulfilled, (state, action) => {
        state.simpleList = action.payload.values.map((user) => ({
          id: user.userId,
          userName: user.name
        }));
      })  
      // fetchRoles
      .addCase(fetchRoles.pending, (state) => {
        state.rolesStatus = ApiStatuses.loading;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.rolesStatus = ApiStatuses.success;
        state.roles = action.payload.values;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.rolesStatus = ApiStatuses.fail;
      })
  },
});

export const users = slice.reducer;
export const actions = slice.actions;
