import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "antd";
import { H3 } from "../../../components/Typography";
import { FormInstance } from "antd/lib/form/Form";
import { LMDropdown, LMNewInput } from "../../../components";
import { IUserFormValues, IUser, EUserAuthType } from "../types";
import { useAppNotifications } from "../../../components/LMNotifications";
import { updateUser } from "../api";
import { useUser } from "../hooks";
import styles from "./UserFormModal.module.css";

interface Props {
  onClose: () => void;
  onRefresh: () => void;
  onSetLoading: (loading: boolean) => void;
  form: FormInstance<IUserFormValues>;
  user: IUser;
}

export const UpdateUserForm: FC<Props> = ({
  onClose,
  onRefresh,
  form,
  user,
  onSetLoading,
}) => {
  const { t } = useTranslation("users");
  const { contextHolder, success, error } = useAppNotifications();
  const { availableUserTypesList, currentUser } = useUser();

  const getInitialValues = useCallback((): Partial<IUserFormValues> => {
    return {
      name: user ? user.name : undefined,
      email: user ? user.email : undefined,
      userType: user ? user.userType : undefined,
    };
  }, [user]);

  useEffect(() => {
    form.setFieldsValue(getInitialValues());
  }, [form, getInitialValues]);

  const onFinish = async (values: IUserFormValues) => {
    onSetLoading(true);
    try {
      await updateUser(user.userId, values);
      await setTimeout(() => {
        onRefresh();
        onSetLoading(false);
        form.resetFields();
        success(t("updateSuccessMessage"));
        onClose();
      }, 3000);
    } catch (e: any) {
      onSetLoading(false);
      error(t("updateErrorMessage"));
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <H3 className={styles.title}>{t("addNew")}</H3>
        <Form
          layout={"vertical"}
          form={form}
          className={styles.form}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={getInitialValues()}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t("name")}
                rules={[{ required: true, message: t("requiredMessage") }]}
              >
                <LMNewInput
                  placeholder={t("namePlaceholder")}
                  disabled={user.authType === EUserAuthType.google}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={t("type")}
                name="userType"
                rules={[{ required: true, message: t("requiredMessage") }]}
              >
                <LMDropdown
                  items={availableUserTypesList.map((type) => ({
                    name: t(type),
                    value: type,
                  }))}
                  placeholder={t("pleaseSelect")}
                  disabled={currentUser?.id === user.userId}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
